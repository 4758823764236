<template>
  <div id="event-photo-page">
    <div class="head">
      <h1>{{ $t("COMMON.TAKE_PHOTO") }}</h1>
    </div>
    <div class="container mt-4">
      <div class="camera-booth">
        <div class="row">
          <div class="col-lg-6 cam-section">
            <div class="video-section">
              <video ref="video" style="width: 100%;" @canplay="initCanvas">
                Stream unavailable
              </video>
              <img :src="imgSrc" class="frame" alt="" />
              <button
                v-if="!capturing"
                class="btn btn-primary"
                @click="takePicture"
              >
                <i class="fa fa-camera" aria-hidden="true"></i>
              </button>
              <p v-else>{{ countDown / 1000 }}</p>
            </div>

            <canvas ref="canvas" style="display: none" />
          </div>
          <img :src="output" />
          <div v-if="file" class="col-lg-6">
            <div
              id="event-photo-section"
              ref="eventPhoto"
              class="event-photo-section"
            >
              <img id="event-photo" :src="file" alt="" />
              <img id="frame" class="frame" :src="imgSrc" alt="" />
            </div>
          </div>
        </div>
        <div v-if="file" class="buttons-section">
          <button
            v-if="!showPrint"
            @click="savePicture"
            class="mt-2 btn btn-primary"
          >
            <i class="fas fa-save"></i>
          </button>
          <div v-else class="mt-2 d-flex align-items-center">
            <button
              @click="printImage"
              v-if="eventDoctor && !eventDoctor.photoPrinted"
              class="btn btn-success"
            >
              <i class="fa fa-print" aria-hidden="true"></i>
            </button>
            <button @click="openVoucher" class="ml-2 btn btn-danger">
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <VoucherModal :vm="this" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VoucherModal from "@/components/VoucherModal";
import domtoimage from "dom-to-image";
import FileSaver from "file-saver";

export default {
  mounted() {
    this.canvas = this.$refs.canvas;
    this.video = this.$refs.video;
    this.startCapture();
  },
  components: {
    VoucherModal,
  },
  watch: {
    countDown() {
      if (this.countDown > 0 && this.capturing) {
        setTimeout(() => {
          this.countDown = this.countDown - 1000;
        }, 1000);
      } else {
        this.takingPicture();
        this.capturing = false;
      }
      // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    ...mapActions({
      uploadEventPhoto: "uploadEventPhoto",
      print: "printImage",
    }),
    startCapture() {
      // console.log("OOOOOOFFFSSSOOOOLLLOOOO");
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          this.video.srcObject = stream;
          this.video.play();
          this.stream = stream;
        })
        .catch((error) => {});
    },
    takePicture() {
      this.capturing = true;
      this.countDown = 5000;
    },
    takingPicture() {
      let context = this.canvas.getContext("2d");
      context.drawImage(
        this.video,
        0,
        0,
        this.video.videoWidth,
        this.video.videoHeight
      );
      this.file = this.canvas.toDataURL("image/png");
    },
    initCanvas() {
      this.canvas.setAttribute("width", this.video.videoWidth);
      this.canvas.setAttribute("height", this.video.videoHeight);
    },
    savePicture() {
      var byteString = atob(this.file.split(",")[1]);

      // separate out the mime component
      var mimeString = this.file
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const data = { blob: new Blob([ab], { type: mimeString }) };
      this.uploadEventPhoto({
        data,
        vm: this,
        eventId: this.$route.params.eventId,
        doctorId: this.$route.params.doctorId,
      });
    },
    stopCapturing() {
      this.video.pause();
      this.video.src = "";
      this.stream.getTracks().forEach(function(track) {
        track.stop();
      });
    },
    openVoucher() {
      if (
        this.event.hasVoucher &&
        this.eventDoctor &&
        !this.eventDoctor.voucherPrinted
      ) {
        this.$bvModal.show("voucher-modal");
      } else {
        this.$router.push({ name: "Thank You" });
      }
    },
    printImage() {
      let that = this;

      domtoimage
        .toBlob(document.getElementById("event-photo-section"))
        .then(function(blob) {
          // FileSaver.saveAs(blob, "hello world.png");
          const file = new File([blob], "event-photo", {
            type: "image/png",
          });

          const formData = new FormData();
          formData.append("data", file);

          that.print({
            eventId: that.$route.params.eventId,
            doctorId: that.$route.params.doctorId,
            formFile: formData,
          });
        });
    },
  },
  data() {
    return {
      video: null,
      canvas: null,
      file: null,
      showPrint: false,
      stream: null,
      countDown: 0,
      capturing: false,
      output: null,
    };
  },
  computed: {
    ...mapGetters({
      frameSelected: "frameSelected",
      event: "event",
      eventDoctor: "eventDoctor",
    }),
    imgSrc() {
      if (this.frameSelected === 1)
        return require("@/assets/media/frames/1.png");
      else return require("@/assets/media/frames/frame.png");
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  background-color: white;
  padding: 15px;
  text-align: center;
  h1 {
    font-weight: 500;
    font-size: 24px;
    color: #3c4eba;
    font-family: fantasy;
  }
}

.frame {
  // height: 854px;
  // width: 480px;
  // height: 908px;
  // width: 510px;
  // width: 530px;
  // height: 943px;
  height: 978px;
  width: 550px;
  position: absolute;
  z-index: 1;
}

.video-section {
  // height: 854px;
  // width: 480px;
  // height: 908px;
  // width: 510px;
  // width: 530px;
  // height: 943px;
  height: 978px;
  width: 550px;
  position: relative;
  video {
    object-fit: initial;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }

  button {
    position: absolute;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    top: 34%;
    left: 29%;
    z-index: 2;

    i {
      font-size: 6.2rem;
      padding-right: 0;
    }
  }

  p {
    height: 200px;
    border-radius: 50%;
    width: 200px;
    position: absolute;
    top: 34%;
    left: 29%;
    background-color: rgba($color: #fff, $alpha: 0.5);
    font-size: 11.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.camera-booth {
  position: relative;
  .buttons-section {
    position: absolute;
    top: 0;
    right: 100px;
    z-index: 2;

    button {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;

      i {
        padding-right: 0 !important;
        font-size: 1.6rem;
      }
    }
  }
}
.event-photo-section {
  position: relative;
  #event-photo {
    // height: 854px;
    // width: 480px;
    // height: 908px;
    // width: 510px;
    // width: 530px;
    // height: 943px;
    height: 978px;
    width: 550px;
    position: absolute;
    z-index: 0;
  }
}

// .button-section {
//   button {
//     width: 150px;
//   }
// }
</style>
